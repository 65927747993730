import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { unionBy } from "lodash"
import TagsList from "../components/tagsList/tagsList"

const TagsIndexPage = ({
  data: {
    allContentfulBlogPost: { group: contentfulTags },
  },
}) => {
  const tags = (contentfulTags || []).filter(value => {
    return value?.fieldValue !== "chinese"
  })
  return <TagsList tags={tags} secondaryMain />
}

export const pageQuery = graphql`
  query {
    allContentfulBlogPost {
      group(field: tagsList) {
        fieldValue
      }
    }
  }
`

TagsList.propTypes = {
  secondaryMain: PropTypes.bool,
  isModal: PropTypes.bool,
  data: PropTypes.any,
}

export default TagsIndexPage
