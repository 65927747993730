const getTagUrl = tag => {
  return `/tags/${tag.toLowerCase().replace(/\s/g, "-")}`
}

const getTagDisplayName = tag => {
  return tag
    .split(" ")
    .map(
      string => string.charAt(0).toUpperCase() + string.slice(1, string.length)
    )
    .join(" ")
}

module.exports = {
  getTagUrl,
  getTagDisplayName,
}
