import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import Layout from "../layout/layout"
import { getTagUrl, getTagDisplayName } from "../../utils/tags"
import "./tagsList.less"

const parseTags = tags => {
  return tags.map(({ fieldValue: tag }) => {
    const tagUrl = getTagUrl(tag)
    const displayName = getTagDisplayName(tag)

    return (
      <li key={tagUrl}>
        <h2>
          <Link to={tagUrl}>{displayName}</Link>
        </h2>
      </li>
    )
  })
}

const TagsList = ({ tags }) => (
  <Layout title="Tags" className="tags">
    <ul className={classNames("tags__list")}>
      <li>
        <Link key="all" to="/">
          <h2>All</h2>
        </Link>
      </li>
      {parseTags(tags)}
    </ul>
  </Layout>
)

TagsList.defaultProps = {
  secondaryMain: false,
}

export default TagsList
